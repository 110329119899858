<script>
import { required, minLength } from "vuelidate/lib/validators";

export default {
  data() {
    return {
      submitted: false,
      loading: false,
      user: {
        username: "",
        password: "",
      },
    };
  },

  created() {
    document.body.classList.add("auth-body-bg");
  },
  validations: {
    user: {
      username: { required },
      password: { required, minLength: minLength(4) },
    },
  },
  methods: {
    handleSubmit(e) { // eslint-disable-line no-unused-vars
      this.submitted = true
      this.loading = true
      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.loading = false
        return;
      }
      this.login();
    },
    login() {
      let username = this.user.username;
      let password = this.user.password;
      const authorizationType = "agent-authentication";
      this.$store
        .dispatch("login", { username, password, authorizationType })
        .then(() => {
          this.$toast.success("Login Successful");
          this.$router.push("/");
        })
        .catch((err) => {
          // eslint-disable-line no-unused-vars
          this.$toast.error(err.response.data.message);
          this.submitted = false;
        });
      this.submitted = false;
    },
  },
};
</script>

<template>
  <div>
    <div class="home-btn d-none d-sm-block">
      <a href="/">
        <i class="mdi mdi-home-variant h2 text-white"></i>
      </a>
    </div>
    <div>
      <div class="container-fluid p-0">
        <div class="row no-gutters">
          <div class="col-lg-4 m-auto">
            <div
              class="authentication-page-content p-4 d-flex align-items-center min-vh-100"
            >
              <div class="w-100">
                <div class="row justify-content-center">
                  <div class="col-lg-9">
                    <div>
                      <div class="text-center">
                        <div>
                          <a href="/" class="logo">
                            <img
                              src="../../../assets/xefe.png"
                              height="170"
                              alt="logo"
                            />
                          </a>
                        </div>

                        <h4 class="font-size-18 mt-4">Welcome Back!</h4>
                        <h4 class="font-size-24 mt-4">Agent Dashboard</h4>
                        <p class="text-muted">
                          Sign in to continue to your dashboard
                        </p>
                      </div>

                      <div class="p-2 mt-5">
                        <form
                          class="form-horizontal"
                          @submit.prevent="handleSubmit()"
                        >
                          <div class="form-group auth-form-group-custom mb-2">
                            <i
                              class="ri-user-2-line auti-custom-input-icon"
                            ></i>
                            <label for="username">Current PPMC Username</label>
                            <input
                              v-model="user.username"
                              type="text"
                              class="form-control"
                              id="username"
                              :class="{
                                'is-invalid':
                                  submitted && $v.user.username.$error,
                              }"
                              placeholder="Enter username"
                            />
                            <div
                              v-if="submitted && !$v.user.username.required"
                              class="invalid-feedback"
                            >
                              Username is required.
                            </div>
                          </div>

                          <div class="form-group auth-form-group-custom mb-4">
                            <i
                              class="ri-lock-2-line auti-custom-input-icon"
                            ></i>
                            <label for="userpassword">Password</label>
                            <input
                              v-model="user.password"
                              type="password"
                              class="form-control"
                              id="userpassword"
                              placeholder="Enter password"
                              :class="{
                                'is-invalid':
                                  submitted && $v.user.password.$error,
                              }"
                            />
                            <div
                              v-if="submitted && !$v.user.password.required"
                              class="invalid-feedback"
                            >
                              Password is required.
                            </div>
                          </div>

                          <div class="mt-4 text-center">
                            <button
                              class="btn btn-primary w-md waves-effect waves-light"
                              type="submit"
                            >
                              <span
                                class="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                                v-if="loading == true"
                              ></span>
                              Log In
                            </button>
                          </div>

                          <div class="mt-4 text-center">
                            <router-link
                              to="/forgot-password"
                              class="text-muted"
                            >
                              <i class="mdi mdi-lock mr-1"></i> Forgot your
                              password?
                            </router-link>
                          </div>
                        </form>
                      </div>

                      <div class="mt-3 text-center">
                        <p>
                          Don't have an account ?
                          <a href="/register/agent">Register</a>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--          <div class="col-lg-8">-->
          <!--            <div class="authentication-bg">-->
          <!--              <div class="bg-overlay"></div>-->
          <!--            </div>-->
          <!--          </div>-->
        </div>
      </div>
    </div>
  </div>
</template>
