var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._m(0),_c('div',[_c('div',{staticClass:"container-fluid p-0"},[_c('div',{staticClass:"row no-gutters"},[_c('div',{staticClass:"col-lg-4 m-auto"},[_c('div',{staticClass:"authentication-page-content p-4 d-flex align-items-center min-vh-100"},[_c('div',{staticClass:"w-100"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-lg-9"},[_c('div',[_vm._m(1),_c('div',{staticClass:"p-2 mt-5"},[_c('form',{staticClass:"form-horizontal",on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit()}}},[_c('div',{staticClass:"form-group auth-form-group-custom mb-2"},[_c('i',{staticClass:"ri-user-2-line auti-custom-input-icon"}),_c('label',{attrs:{"for":"username"}},[_vm._v("Current PPMC Username")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.user.username),expression:"user.username"}],staticClass:"form-control",class:{
                              'is-invalid':
                                _vm.submitted && _vm.$v.user.username.$error,
                            },attrs:{"type":"text","id":"username","placeholder":"Enter username"},domProps:{"value":(_vm.user.username)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.user, "username", $event.target.value)}}}),(_vm.submitted && !_vm.$v.user.username.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Username is required. ")]):_vm._e()]),_c('div',{staticClass:"form-group auth-form-group-custom mb-4"},[_c('i',{staticClass:"ri-lock-2-line auti-custom-input-icon"}),_c('label',{attrs:{"for":"userpassword"}},[_vm._v("Password")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.user.password),expression:"user.password"}],staticClass:"form-control",class:{
                              'is-invalid':
                                _vm.submitted && _vm.$v.user.password.$error,
                            },attrs:{"type":"password","id":"userpassword","placeholder":"Enter password"},domProps:{"value":(_vm.user.password)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.user, "password", $event.target.value)}}}),(_vm.submitted && !_vm.$v.user.password.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Password is required. ")]):_vm._e()]),_c('div',{staticClass:"mt-4 text-center"},[_c('button',{staticClass:"btn btn-primary w-md waves-effect waves-light",attrs:{"type":"submit"}},[(_vm.loading == true)?_c('span',{staticClass:"spinner-border spinner-border-sm",attrs:{"role":"status","aria-hidden":"true"}}):_vm._e(),_vm._v(" Log In ")])]),_c('div',{staticClass:"mt-4 text-center"},[_c('router-link',{staticClass:"text-muted",attrs:{"to":"/forgot-password"}},[_c('i',{staticClass:"mdi mdi-lock mr-1"}),_vm._v(" Forgot your password? ")])],1)])]),_vm._m(2)])])])])])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"home-btn d-none d-sm-block"},[_c('a',{attrs:{"href":"/"}},[_c('i',{staticClass:"mdi mdi-home-variant h2 text-white"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-center"},[_c('div',[_c('a',{staticClass:"logo",attrs:{"href":"/"}},[_c('img',{attrs:{"src":require("../../../assets/xefe.png"),"height":"170","alt":"logo"}})])]),_c('h4',{staticClass:"font-size-18 mt-4"},[_vm._v("Welcome Back!")]),_c('h4',{staticClass:"font-size-24 mt-4"},[_vm._v("Agent Dashboard")]),_c('p',{staticClass:"text-muted"},[_vm._v(" Sign in to continue to your dashboard ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt-3 text-center"},[_c('p',[_vm._v(" Don't have an account ? "),_c('a',{attrs:{"href":"/register/agent"}},[_vm._v("Register")])])])
}]

export { render, staticRenderFns }